#background {
  display: initial;
  z-index: -5;
  object-fit: cover;
  object-position: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
}

#animate {
  display: initial;
}

body {
  color: #fff4ee;
  background-color: #000;
  background-image: url("movie.0aa1d64f.png");
  background-position: center;
}

a:link {
  color: #be747a;
}

a:visited {
  color: #91444a;
}

a:hover {
  color: #c6af8c;
}

a:active {
  color: #949ba3;
}

/*# sourceMappingURL=index.b7e38bc4.css.map */
